import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Stack,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import CountUp from "react-countup";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import h11 from "../../../assets/images/h11.jpg";
import chatBot from "../../../assets/images/chatbot.png";
import h3 from "../../../assets/images/h3.png";
import officer from "../../../assets/images/officer2.jpg";
import carousal1 from "../../../assets/images/security/carousal1.jpg";
import carousal2 from "../../../assets/images/security/carousal2.jpg";
import AnimationOnScroll from "../../../components/AnimationOnScroll/AnimationOnScroll";
import ContactForm from "../../../components/Forms/ContactForm";
import SchedualAppointment from "../../../components/Modals/SchedualAppointment";
import Service1 from "../../../components/cards/Service1";
import Service2 from "../../../components/cards/Service2";
import Service3 from "../../../components/cards/Service3";
import SiteMasterLayout from "../../masterLayout/SiteMasterLayout";
import { withSwal } from "react-sweetalert2";
import { FaPhone } from "react-icons/fa6";
import { TiMessageTyping } from "react-icons/ti";


const Home = ({ swal }) => {
  const navigate = useNavigate();
  const [showBotToast, setShowBotToast] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const carouselItems = [
    {
      heading: "We Provide Verified & Secured Service for company",
      detail:
        "Our services are thoroughly verified and designed to ensure the highest level of security, giving your business peace of mind and reliability at every step.",
      image: carousal1,
    },
    {
      heading: "Grow your business more secure",
      detail:
        "Protect your assets and operations with our comprehensive security solutions, allowing your business to thrive in a safe and secure environment.",
      image: carousal2,
    },
  ];
  const whyChooseUs = [
    {
      heading: "24/7 Support",
      detail:
        "We provide round-the-clock support to ensure your business operates smoothly at all times, addressing any concerns instantly.",
      icon: "PiHandshakeLight",
    },
    {
      heading: "Expert Employees",
      detail:
        "Our skilled professionals bring years of expertise to deliver high-quality results for every task.",
      icon: "RiUser3Line",
    },
    {
      heading: "Verified Service",
      detail:
        "We offer thoroughly vetted services, ensuring you receive only the best, reliable, and trusted solutions.",
      icon: "SlLock",
    },
    {
      heading: "Secured Service",
      detail:
        "Your security is our priority, with advanced technologies in place to protect your data and services at all times.",
      icon: "TbShieldLock",
    },
  ];
  const [services, setServices] = useState([]);
  const serviceQuestions = [
    {
      heading: "Can I get help 24/7?",
      detail:
        "Yes, our support team is available around the clock to assist with any inquiries or issues you may have.",
    },
    {
      heading: "Can I get refund ?",
      detail:
        " Yes, we offer a clear and simple refund policy to ensure your satisfaction with our services.",
    },
    {
      heading: "How much exprience Cornwall Security group have ?",
      detail:
        "We have years of expertise in providing top-notch security and services, backed by a dedicated team of professionals.",
    },
  ];
  const [isScrolled, setIsScrolled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [phoneForDialer, setPhoneForDialer] = useState("");

  const getAllServices = () => {
    axios({
      method: "get",
      url: `https://admin.cornwallsecuritygroup.com/api/guest/getFirstThreeServices`,
    })
      .then((response) => {
        console.log(response);
        console.log("//////////////////////////");
        console.log(response.data.threeServices);
        setServices(response.data.threeServices);
        console.log("//////////////////////////");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showPhoneAndEmail = () => {
    axios({
      method: "get",
      url: `https://admin.cornwallsecuritygroup.com/api/guest/showEmailAndPhone`,
    })
      .then((resp) => {
        console.log(resp.data);
        setPhoneForDialer(`tel:${resp.data.showPhone}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // alert(`width ${window.innerWidth}  height ${window.innerHeight}`);
    setTimeout(() => {
      setShowBotToast(true);
    }, 5000);

    getAllServices();
    showPhoneAndEmail();
    const handleScroll = () => {
      if (window.scrollY >= 250) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <SiteMasterLayout>
      <Container fluid className=" p-0 mb-5">
        {showBotToast ? null : (
          <Button
            style={{
              height: isDesktopOrLaptop ? 100 : 70,
              width: isDesktopOrLaptop ? 100 : 70,
              borderRadius: "100%",
              position: "absolute",
              bottom: 50,
              right: isDesktopOrLaptop ? 50 : 10,
              zIndex: 999,
            }}
            className="btn btn-warning"
            onClick={() => {
              setShowBotToast(true);
            }}
          >
            <TiMessageTyping
              size={isDesktopOrLaptop ? 50 : 30}
              // style={{ margin: 25 }}
              color={"#020E2A"}
            />
          </Button>
        )}

        {showBotToast ? (
          <ToastContainer
            position="bottom-end"
            className="p-3"
            style={{ zIndex: 999, height: 250, marginBottom: 50 }}
          >
            <Toast
              style={{
                zIndex: 999,
                height: 250,
                marginBottom: 20,
                backgroundColor: "#020E2A",
              }}
              onClose={() => {
                setShowBotToast(false);
              }}
              show={true}
              animation={true}
            >
              <Toast.Header>
                <strong className="me-auto poppins-bold">
                  Welcome to CornWallSecurityGroup
                </strong>
              </Toast.Header>
              <Image
                fluid
                src={chatBot}
                alt="Image"
                style={{
                  borderRadius: "100%",
                  height: "100px",
                  width: "100px",
                  objectFit: "cover",
                  marginLeft: "35%",
                  marginTop: 20,
                }}
              />
              <Toast.Body className="poppins-bold text-light text-center">
                Want to talk with one of our agent ?
                <a
                  className="btn btn-sm btn-warning mt-2"
                  href={phoneForDialer}
                >
                  <FaPhone size={18} className="me-3" />
                  CALL US
                </a>
              </Toast.Body>
            </Toast>
          </ToastContainer>
        ) : null}
        <Carousel
          data-bs-theme="light"
          className="m-0 p-0"
          fade={true}
          indicators={false}
          controls={false}
          slide={true}
          interval={3000}
        >
          {carouselItems.map((item, index) => {
            return (
              <Carousel.Item key={index}>
                <Image
                  fluid
                  className={`carousel-image`}
                  src={item.image}
                  alt="First slide"
                />

                <Carousel.Caption className="carousel-caption-container res-medium-text">
                  <AnimationOnScroll direction={"leftToRight"}>
                    <h1
                      className={`text-light poppins-semiBold ${
                        isDesktopOrLaptop ? "w-50" : "w-100"
                      }`}
                    >
                      {item.heading}
                    </h1>
                  </AnimationOnScroll>
                  <AnimationOnScroll direction={"downToUp"}>
                    <p
                      className={`text-light poppins-regular mt-3 ${
                        isDesktopOrLaptop ? "w-50" : "w-100"
                      }`}
                    >
                      {item.detail}
                    </p>
                  </AnimationOnScroll>
                  <Button
                    variant="outline-warning"
                    className="me-2 mt-4 poppins-regular"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Schedule An Appoinment
                  </Button>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
        <Row className={`my-5 py-5 content-container`}>
          <Col lg={6} md={12} sm={12} xs={12} className={`  mt-5 res-text  `}>
            <h2 className="poppins-semiBold ">
              Detect <span className="txt-yellow">Deter</span> Defend our
              clients Asset
            </h2>
            <p className="poppins-regular">
              Since our founding, Cornwall Security Group; LLC has been known
              for quality services, exceptional efficiency and the highest level
              of professionalism. No matter what service you’re looking for, we
              guarantee to not only meet, but exceed your expectations and
              ensure your full satisfaction.
              <br />
              <br />
              Our team is up for every job, managing projects with the skill and
              experience our clients have come to expect. Please get in touch to
              learn more about our team, our company or for details about our
              security services we provide.
            </p>
            <Stack direction="horizontal" gap={"2"}>
              <Stack gap={"2"}>
                <CountUp
                  start={1}
                  // end={250}
                  end={isScrolled ? 250 : 0}
                  duration={5}
                  suffix="K"
                  className=" poppins-bold"
                  style={{ fontSize: "32px" }}
                />
                <h6 className="poppins-medium">Total Guard Hours</h6>
              </Stack>

              <Stack gap={"2"}>
                <CountUp
                  start={1}
                  // end={100}
                  end={isScrolled ? 100 : 0}
                  duration={5}
                  suffix="K"
                  className=" poppins-bold"
                  style={{ fontSize: "32px" }}
                />
                <h6 className="poppins-medium">Total Armed Hours</h6>
              </Stack>
              <Stack gap={"2"}>
                <CountUp
                  start={1}
                  // end={17}
                  end={isScrolled ? 17 : 0}
                  duration={5}
                  // suffix="K"
                  className=" poppins-bold"
                  style={{ fontSize: "32px" }}
                />
                <h6 className="poppins-medium">Property Types</h6>
              </Stack>
            </Stack>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`d-flex justify-content-center align-items-center mt-5`}
            style={{}}
          >
            <Container
              style={{
                height: "320px",
                width: "460px",
                backgroundColor: "#FAB909",
                position: "relative",
              }}
            >
              <Image
                fluid
                src={h11}
                alt="Image"
                style={{
                  position: "absolute",
                  left: "20px",
                  bottom: "20px",
                  height: "320px",
                  width: "460px",
                  objectFit: "cover",
                }}
              />
            </Container>
          </Col>
        </Row>
        <Row className="mt-5 ">
          <Col lg={6} md={12} sm={12} xs={12} className={`m-0 px-0 `}>
            <Image
              src={officer}
              alt="Image"
              style={{ objectFit: "cover", height: "700px", width: "100%" }}
            />
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} className={`bg-gray  py-4`}>
            <h1 className="poppins-semiBold mx-4">
              <span className="txt-yellow">Why</span> Choose Us
            </h1>
            <Row className="my-2 p-0 ps-3 ">
              {whyChooseUs.map((item, index) => {
                return (
                  <Col xs="12" lg="5" className="my-1" key={index}>
                    <Service1 item={item} />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row className="py-5 content-container bg-blue">
          <h1 className="txt-white poppins-bold res-text">
            What We <span className="txt-yellow">Offer</span> Here
          </h1>
          <p className="poppins-regular txt-white my-3">
            Provide Random drive-by security patrols,Guard station at client’s
            premises, or alarm and security systems, including monitoring from
            station and responding to calls.
          </p>
          {services.map((item, index) => {
            return <Service2 key={index} item={item} />;
          })}
          <Container className="d-flex justify-content-end px-5 mt-5">
            <Button
              variant="outline-warning"
              size="md"
              className=" mx-3 px-5"
              onClick={() => {
                navigate("/services");
              }}
            >
              View All
            </Button>
          </Container>
        </Row>
        <Row className={`my-5 py-4 content-container `}>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`d-flex justify-content-center align-items-center `}
          >
            <Image fluid src={h3} alt="Image" />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`res-text ${isDesktopOrLaptop && "ps-4"}  `}
          >
            <h1 className="poppins-semiBold my-3">
              Using <span className="txt-yellow">Advance Tech</span> to Serve
              You
            </h1>
            <p className="poppins-regular">
              The Mission of Cornwall Security Group; LLC is to achieve the
              highest quality standards in the security industry. The board at
              Cornwall Security Group; LLC is committed and dedicated to secure
              and provide our clients with a healthy working environment free of
              theft, unauthorized solicitation plus a clear sense of workplace
              comfort. Our core values are designed around trust, integrity and
              professionalism.
            </p>
          </Col>
        </Row>
        <Row className="my-5 flex-column flex-lg-row">
          <Col lg={6} md={12} sm={12} xs={12} className="px-0 d-block">
            <Container fluid className="home-image">
              <Row className="overlay m-0">
                {serviceQuestions.map((item, index) => (
                  <Service3 item={item} />
                ))}
              </Row>
            </Container>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`bg-gray  px-5  ${"py-4"}`}
            style={{ overflow: "visible" }}
          >
            <ContactForm />
          </Col>
        </Row>
      </Container>
      <SchedualAppointment
        isVisible={showModal}
        hideModel={() => {
          setShowModal(false);
        }}
      />
    </SiteMasterLayout>
  );
};

export default withSwal(Home);
