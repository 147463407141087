import React from "react";
import { Card, Container } from "react-bootstrap";
import { BsQuestionOctagon, BsFillPatchQuestionFill } from "react-icons/bs";

const Service3 = ({ item }) => {
  return (
    <Card className="service3">
      <Container className="d-flex align-items-center w-auto">
        <BsFillPatchQuestionFill
          size="40px"
          className="text-warning align-self-start mt-2"
        />
      </Container>

      <Card.Body className="m-1 p-1 res-medium-text">
        <Card.Title className="poppins-semiBold  ">{item.heading}</Card.Title>
        <p className=" mt-2 poppins-regular ">
         {item.detail}
        </p>
        {/* <Card.Subtitle className="poppins-medium res-small-text">
          Continue Reading...
        </Card.Subtitle> */}
      </Card.Body>
    </Card>
  );
};

export default Service3;
