import React from "react";
import { Card, Container } from "react-bootstrap";

import { GiFishingBoat ,GiHomeGarage} from "react-icons/gi";
import { IoConstructOutline } from "react-icons/io5";
import { LiaServicestack } from "react-icons/lia";

const Service2 = ({ item }) => {
  const iconMapping = {
    GiHomeGarage: GiHomeGarage,
    GiFishingBoat:GiFishingBoat,
    IoConstructOutline:IoConstructOutline,
  };
  // const IconComponent = iconMapping[item.icon];
  return (
    <Card className=" mx-4 mt-4 py-3 px-1 service2">
       <Container className="d-flex align-items-center w-auto ">
      {/* <IconComponent size={"32"} className="align-self-start mt-2" /> */}
      <LiaServicestack size={"32"} className="align-self-start mt-2" />
      </Container>


      <Card.Body className="p-1 m-1 res-medium-text ">
        <Card.Title className="poppins-semiBold  ">{item.title}</Card.Title>
        <p className=" mt-3 mb-0 poppins-regular ">{item.description}</p>
        <p className="poppins-medium mt-1 m-0">Continue Reading...</p>
        {/* <Card.Subtitle className="poppins-medium res-small-text">
          Continue Reading...
        </Card.Subtitle> */}
      </Card.Body>
    </Card>
  );
};

export default Service2;
