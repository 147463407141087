import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Spinner,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import "../../../assets/css/auth/LoginStyles.css";
import "../../../assets/css/fontStyles.css";
import { scrollToTop } from "../../../helper/HelperFunctions";

const Login = () => {
  const navigate = useNavigate();

  const [errorMessages, setErrorMessages] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const adminLogin = (em = null, ps = null) => {
    axios
      .post("https://admin.cornwallsecuritygroup.com/api/admin/login", {
        email: em == null ? email : em,
        password: ps == null ? password : ps,
      })
      .then((resp) => {
        console.log(resp.data);
        setLoading(false);
        localStorage.setItem("admin", JSON.stringify(resp.data));
        const adminCredentials = { email: email, password: password };
        localStorage.setItem(
          "adminCredentials",
          JSON.stringify(adminCredentials)
        );

        navigate("/dashboard");
      })
      .catch((err) => {
        setErrorMessages(err.response.data.error);
        setLoading(false);
      });
  };

  useEffect(() => {
    scrollToTop();
    if (localStorage.getItem("admin") != null) {
      const admin = JSON.parse(localStorage.getItem("adminCredentials"));
      adminLogin(admin.email, admin.password);
    } else {
      setPageLoading(false);
    }
  }, []);

  return pageLoading ? (
    <Row className="d-flex justify-content-lg-center">
      <Spinner style={{ width: "50px", height: "50px", marginTop: "20%" }} />
    </Row>
  ) : (
    <Container fluid style={{ height: "100vh" }} className="bg-blue pt-5">
      <Row className="justify-content-center justify-content-center">
        <Col lg="2"></Col>
        <Col lg="8">
          <Row>
            <Col lg={3}></Col>
            <Col lg={6}>
              <Image
                style={{ marginTop: 50, marginBottom: 50 }}
                src={require("../../../assets/images/security-logo.png")}
                thumbnail
              />
            </Col>
            <Col lg={3}></Col>
          </Row>

          <Container fluid className="py-3 px-0 ">
            <Row className="g-3">
              <Col xs={12} md={6} lg={12} className="my-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control
                    className="poppins-regular"
                    type="text"
                    placeholder="Email"
                    size="lg"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-3">
              <Col xs={12} md={6} lg={12} className="my-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control
                    className="poppins-regular"
                    type="password"
                    placeholder="************"
                    size="lg"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={3}></Col>
              <Col lg={6}>
                {loading ? (
                  <div style={{ marginLeft: "45%", marginTop: "20%" }}>
                    <Spinner animation="border" variant="light" />
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      if (email == "") {
                        setErrorMessages("Email required");
                      } else if (password == "") {
                        setErrorMessages("Password required");
                      } else {
                        setLoading(true);
                        adminLogin();
                      }
                    }}
                    type="submit"
                    variant="outline-light"
                    size="md"
                    className="poppins-semiBold  mt-4 mx-3"
                    style={{ width: "90%" }}
                  >
                    Login
                  </Button>
                )}
              </Col>
              <Col lg={3}></Col>
            </Row>
          </Container>
        </Col>
        <Col lg="2"></Col>
        {errorMessages != "" ? (
          <ToastContainer
            position="top-end"
            className="p-3"
            style={{ zIndex: 1 }}
          >
            <Toast
              bg="danger"
              onClose={() => {
                setErrorMessages("");
              }}
              show={errorMessages == "" ? false : true}
              animation={true}
            >
              <Toast.Header>
                <strong className="me-auto poppins-bold">IMPORTANT</strong>
              </Toast.Header>
              <Toast.Body className="poppins-regular text-light">
                {errorMessages}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        ) : (
          <p></p>
        )}
      </Row>
    </Container>
  );
};

export default Login;
