import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Form,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { withSwal } from "react-sweetalert2";


const ContactForm = ({swal}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  
  const [allServices,setAllServices]=useState([])
  const [selectedOption, setSelectedOption] = useState('Services Required');
  const [user, setUser] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [formValidate, setFormValidate] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const errors = {};

    if (!user.name) {
      errors.name = "Name is required";
    }
    if (!user.email) {
      errors.email = "Email is required";
    }
    if (!user.phone) {
      errors.phone = "Phone# is required";
    }
    if (!user.company) {
      errors.company = "Company Name is required";
    }
    if (!user.zipcode) {
      errors.zipcode = "Zipcode is required";
    }
    if (!user.service || user.service == "Services Required") {
      errors.service = "Select an option";
    }
    if (!user.message) {
      errors.message = "Message is required";
    }
    setErrorMessages(errors);
    if (Object.keys(errors).length === 0) {
      storeContact();
    } else {
      setFormValidate(true);
    }
  };
  const storeContact = () => {
    const formData = new FormData();
    formData.append("name", `${user.name}`);
    formData.append("phone", `${user.phone}`);
    formData.append("email", `${user.email}`);
    formData.append("zip_code", `${user.zipcode}`);
    formData.append("company_name", `${user.company}`);
    formData.append("service_required", `${user.service}`);
    formData.append("message", `${user.message}`);

    axios({
      method: "post",
      url: `https://admin.cornwallsecuritygroup.com/api/guest/storeContactDetails`,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then((response) => {
        console.log(response);
        console.log("/////////////Contact Form Response/////////////");
        console.log(response.data.success);
        if (response.data.success == "Contact created successfully") {
          // alert("Form submitted successfully");
          swal.fire({
            title: "Success",
            text: response.data.success,
            icon: "success",
          });
          setUser({});
        }
      })
      .catch((error) => {
        console.log(error);
        swal.fire({
          title: "Error",
          text: 'Something went wrong please try again later',
          icon: "error",
        });
      });
  };
  const getAllServices=()=>{
    axios({
      method: "get",
      url: `https://admin.cornwallsecuritygroup.com/api/guest/getAllServices`,
    }) .then((response) => {
      console.log(response);
      console.log("//////////////////////////");
      console.log(response.data.services);
      setAllServices(response.data.services);
      console.log("//////////////////////////");

     })
    .catch((error) => {
      console.log(error);
    });
    }
    useEffect(()=>{
      getAllServices()
    },[])

  return (
    <Form
      noValidate
      validated={formValidate}
      onSubmit={handleSubmit}
      className=""
    >
      <h2 className="poppins-semiBold my-3 txt-black ">Get in Touch</h2>
      <Form.Text className="poppins-medium my-5 txt-black ">
        Our experts will reply to you very soon
      </Form.Text>
      <Container fluid className="py-3 px-0 ">
        <Row className="g-3">
          <Col xs={12} md={6} lg={5} className="my-3">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Control
                className="poppins-regular input"
                type="text"
                placeholder="Name"
                size="md"
                value={user.name || ""}
                onChange={(e) => setUser({ ...user, name: e.target.value })}
                required
              />
              {errorMessages.name && (
                <p className="text-danger">{errorMessages.name}</p>
              )}
            </Form.Group>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={5}
            className={`my-3 ${isDesktopOrLaptop && "mx-4"} `}
          >
            <Form.Group controlId="exampleForm.ControlInput2">
              <Form.Control
                className="poppins-regular input"
                type="email"
                placeholder="E-mail"
                size="md"
                value={user.email || ""}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                required
              />
              {errorMessages.email && (
                <p className="text-danger">{errorMessages.email}</p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-3">
          <Col xs={12} md={6} lg={5} className="my-3">
            <Form.Group controlId="exampleForm.ControlInput3">
              <Form.Control
                className="poppins-regular input"
                type="tel"
                placeholder="Phone number"
                pattern="[0-9]{10}"
                size="md"
                value={user.phone || ""}
                onChange={(e) => setUser({ ...user, phone: e.target.value })}
                required
              />
              {errorMessages.phone && (
                <p className="text-danger">{errorMessages.phone}</p>
              )}
            </Form.Group>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={5}
            className={`my-3 ${isDesktopOrLaptop && "mx-4"} `}
          >
            <Form.Group controlId="exampleForm.ControlInput4">
              <Form.Control
                className="poppins-regular input"
                type="text"
                placeholder="Company-Name"
                size="md"
                value={user.company || ""}
                onChange={(e) => setUser({ ...user, company: e.target.value })}
                required
              />
              {errorMessages.company && (
                <p className="text-danger">{errorMessages.company}</p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-3">
          <Col xs={12} md={6} lg={5} className="my-3">
            <Form.Group controlId="exampleForm.ControlInput5">
              <Form.Control
                className="poppins-regular input"
                type="number"
                placeholder="Zipcode"
                size="md"
                value={user.zipcode || ""}
                onChange={(e) => setUser({ ...user, zipcode: e.target.value })}
                required
              />
              {errorMessages.zipcode && (
                <p className="text-danger">{errorMessages.zipcode}</p>
              )}
            </Form.Group>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={5}
            className={`my-3 ${isDesktopOrLaptop && "mx-4"} `}
          >
            <Form.Group controlId="exampleForm.ControlInput6">
              <DropdownButton
                variant="outline-secondary"
                title={selectedOption}
                id="input-group-dropdown-1"
                className="poppins-regular w-70"
              >
                {allServices.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setSelectedOption(item.title);
                      setUser({ ...user, service: item.title });
                    }}
                    style={{
                      backgroundColor:
                        item === selectedOption ? "#f0f0f0" : "transparent",
                      color: item === selectedOption ? "#333" : "inherit",
                    }}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              {errorMessages.service && (
                <p className="text-danger">{errorMessages.service}</p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="my-2 " controlId="ControlInput7">
          <Form.Control
            style={{ width: isDesktopOrLaptop ? "470px" : "318px" }}
            as="textarea"
            rows={3}
            className="poppins-regular input "
            placeholder="Message"
            value={user.message || ""}
            onChange={(e) => setUser({ ...user, message: e.target.value })}
            required
          />
          {errorMessages.message && (
            <p className="text-danger">{errorMessages.message}</p>
          )}
        </Form.Group>
        <Button
          type="submit"
          variant="outline-dark"
          size="md"
          className="poppins-semiBold px-5 my-4"
        >
          Submit
        </Button>
      </Container>
    </Form>
  );
};

export default withSwal(ContactForm);
