import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Spinner, Table } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../helper/HelperFunctions";
import MasterLayout from "../master/MasterLayout";

const Contacts = () => {
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [accessToken, setAccessToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [allContacts, setAllContacts] = useState([]);

  const getAllContacts = (accessToken) => {
    axios
      .get("https://admin.cornwallsecuritygroup.com/api/contacts/getContacts", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        console.log(response);
        console.log(response.data.contacts);
        setAllContacts(response.data.contacts);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    scrollToTop();
    setTimeout(() => {
      if (localStorage.getItem("admin") == null) {
        navigate("/");
      } else {
        const admin = JSON.parse(localStorage.getItem("admin"));
        setAccessToken(admin.access_token);
        getAllContacts(admin.access_token);
        setLoading(false);
      }
    }, 1000);
  }, []);

  return loading ? (
    <Row className="d-flex justify-content-lg-center">
      <Spinner style={{ width: "50px", height: "50px", marginTop: "20%" }} />
    </Row>
  ) : (
    <MasterLayout>
      <Container fluid className="m-0 p-0">
        <h1 className="poppins-semiBold">All Contacts Appoinments</h1>
        <Table
          responsive
          bordered
          striped
          // hover
          // variant="primary"
          // variant="light"
          size={isDesktopOrLaptop ? "lg" : "sm"}
          className=" my-4  "
        >
          <thead>
            <tr>
              <th className="poppins-semiBold">#</th>
              <th className="poppins-semiBold">Name</th>
              <th className="poppins-semiBold">Email</th>
              <th className="poppins-semiBold">Phone</th>
              <th className="poppins-semiBold">Zip Code</th>
              <th className="poppins-semiBold">Company</th>
              <th className="poppins-semiBold">Service</th>
              <th className="poppins-semiBold">Message</th>
            </tr>
          </thead>
          <tbody>
            {allContacts.map((item, index) => (
              <tr key={index} className="res-small-text">
                <td className="poppins-regular">{index + 1}</td>
                <td className="poppins-regular ">{item.name}</td>
                <td className="poppins-regular">{item.email}</td>
                <td className="poppins-regular">{item.phone}</td>
                <td className="poppins-regular">{item.zip_code}</td>
                <td className="poppins-regular">{item.company_name}</td>
                <td className="poppins-regular">{item.service_required}</td>
                <td className="poppins-regular">{item.message}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </MasterLayout>
  );
};

export default Contacts;
