import React from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { FaBorderAll, FaCalendarDay } from "react-icons/fa";
import { MdMiscellaneousServices } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { withSwal } from "react-sweetalert2";
import { FaCalendarCheck } from "react-icons/fa";


const Sidebar = ({ swal }) => {
  const logo = require("../../assets/images/security-logo.png");
  const navigate = useNavigate();

  return (
    <Container
      className="d-flex flex-column"
      style={{
        // backgroundColor:'rgba(225,231,238,0.6)',
        backgroundColor: "#E5e5e5",
        // backgroundColor: "#EEECEC",
        height: "100%",
        width: "22%",
        position: "fixed",
        top: 0,
        left: 0,
        overflow: "auto",
        paddingBlock: "3%",
        paddingInline: "1.5%",
        boxShadow: "0 0px 8px 1px rgba(0, 0, 0, 0.2)",
      }}
    >
      <img
        src={logo}
        style={{
          width: "50%",
          objectFit: "contain",
          alignSelf: "center",
          marginTop: "20px",
        }}
      />
      <br />
      
      <Container className="mt-3 Heading-Text">
        <p
          className="poppins-bold Heading-Text"
          style={{ fontSize: "13px" }}
        >
          Analytics
        </p>
        <Card
          className={`poppins-regular p-2 mt-2 ${
            window.location.pathname === "/dashboard"
              ? "sideNav-card-active"
              : "sideNav-card"
          } `}
        >
          <Row>
            <Col xs={2}>
              <FaBorderAll size={20} className="Heading-Text" />
            </Col>
            <Col xs={10}>
              <Nav.Link
                className="Heading-Text"
                as={Link}
                style={{ textDecoration: "none" }}
                to={"/dashboard"}
              >
                Dashboard
              </Nav.Link>
            </Col>
          </Row>
        </Card>

        <br />
        <hr />

        <p
          className="poppins-bold mt-4 Heading-Text"
          style={{ fontSize: "13px" }}
        >
          Managements
        </p>

        <Card
          className={`poppins-regular p-2 mt-2 ${
            window.location.pathname === "/airdrop"
              ? "sideNav-card-active"
              : "sideNav-card"
          } `}
        >
          <Row>
            <Col xs={2}>
              <MdMiscellaneousServices size={20} className="Heading-Text" />
            </Col>
            <Col xs={10}>
              <Nav.Link
                as={Link}
                style={{ textDecoration: "none" }}
                className="Heading-Text"
                to={"/manage-services"}
                
              >
                Service Management
              </Nav.Link>
            </Col>
          </Row>
        </Card>

        {/* <Card
          className={`poppins-regular p-2 mt-2 ${
            window.location.pathname === "/presale"
              ? "sideNav-card-active"
              : "sideNav-card"
          } `}
        >
          <Row>
            <Col xs={2}>
              <GiGuards size={20} className="Heading-Text" />
            </Col>
            <Col xs={10}>
              <Link
                style={{ textDecoration: "none" }}
                className="Heading-Text"
                to="/presale"
              >
                Guards Management
              </Link>
            </Col>
          </Row>
        </Card> */}

        <Card
          className={`poppins-regular p-2 mt-2 ${
            window.location.pathname === "/appointments-upcoming"
              ? "sideNav-card-active"
              : "sideNav-card"
          } `}
        >
          <Row>
            <Col xs={2}>
              <FaCalendarDay size={20} className="Heading-Text" />
            </Col>
            <Col xs={10}>
              <Link
                style={{ textDecoration: "none" }}
                className="Heading-Text"
                to="/appointments-upcoming"
              >
                Upcoming Appointments
              </Link>
            </Col>
          </Row>
        </Card>

        <Card
          className={`poppins-regular p-2 mt-2 ${
            window.location.pathname === "/appointments-completed"
              ? "sideNav-card-active"
              : "sideNav-card"
          } `}
        >
          <Row>
            <Col xs={2}>
              <FaCalendarCheck size={20} className="Heading-Text" />
            </Col>
            <Col xs={10}>
              <Link
                style={{ textDecoration: "none" }}
                className="Heading-Text"
                to="/appointments-completed"
              >
                Completed Appointments
              </Link>
            </Col>
          </Row>
        </Card>

        <Card
          className={`poppins-regular p-2 mt-2 ${
            window.location.pathname === "/contacts"
              ? "sideNav-card-active"
              : "sideNav-card"
          } `}
        >
          <Row>
            <Col xs={2}>
              <BiSolidMessageSquareDetail size={20} className="Heading-Text" />
            </Col>
            <Col xs={10}>
              <Link
                style={{ textDecoration: "none" }}
                className="Heading-Text"
                to="/contacts"
              >
                Contacts
              </Link>
            </Col>
          </Row>
        </Card>

        

        <br />
        <hr />

        <p
          className="poppins-bold mt-4 Heading-Text"
          style={{ fontSize: "13px" }}
        >
          Disconnect Your Visit
        </p>
        <Card className="poppins-regular p-2 mt-2 sideNav-card">
          <Row>
            {/* <Col xs={2}>
              <AiOutlineDisconnect size={20} className="Heading-Text" />
            </Col> */}
            <Col xs={12}>
              <Button
                
                style={{ textDecoration: "none",width:"100%" }}
                onClick={() => {
                  
                  localStorage.removeItem("admin");
                  navigate("/");
                }}
                
                className="poppins-bold btn btn-sm btn-light"
              >
                Logout
              </Button>
            </Col>
          </Row>
        </Card>
      </Container>
    </Container>
  );
};

export default withSwal(Sidebar);
