import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";

import { CiCircleChevUp } from "react-icons/ci";
import { FaCircleChevronUp } from "react-icons/fa6";
import { TbCircleChevronUp } from "react-icons/tb";
import { BiSolidChevronUpCircle } from "react-icons/bi";
import { FaChevronUp } from "react-icons/fa6";
import { Link } from "react-router-dom";
import {scrollToTop} from '../../helper/HelperFunctions'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const SiteMasterLayout = ({ children }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 350) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Container fluid className="p-0 m-0 d-flex flex-column">
      <Header />
      <main className="" >{children}</main>
      <Footer/>

       {/* scrollToTop button */}
      {/* <Button
        onClick={scrollToTop}
        className="d-flex flex-row justify-content-end"
      >
        <CiCircleChevUp
          className="m-5"
          style={{ 
          position: "fixed",
          top: "80%", 
          right: "5px", 
          zIndex: "1000",
        }}
          color={
            !isScrolled && window.location.pathname == "/"
              ? "#FFFFFF"
              : "#000000"
          }
          size={"60px"}
        />
      </Button> */}
    </Container>
  );
};

export default SiteMasterLayout;
