import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const ServiceModal = ({ isVisible, hideModel,item }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return (
    <Modal
      centered
      show={isVisible}
      onHide={hideModel}
      // size={isDesktopOrLaptop ? "md" : "sm"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="poppins-semiBold">{item.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="poppins-regular">{item.description}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            hideModel();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ServiceModal;
