import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { withSwal } from "react-sweetalert2";

const SchedualAppointment = ({ isVisible, hideModel, swal }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [user, setUser] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [formValidate, setFormValidate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const errors = {};

    if (!user.name) {
      errors.name = "Name is required";
    }
    if (!user.email) {
      errors.email = "Email is required";
    }
    if (!user.phone) {
      errors.phone = "Phone# is required";
    }
    if (!user.date) {
      errors.date = "date is required";
    }
    if (!user.time) {
      errors.time = "time is required";
    }
    if (!user.message) {
      errors.message = "Message is required";
    }
    setErrorMessages(errors);
    if (Object.keys(errors).length === 0) {
      storeAppointment();
    } else {
      setFormValidate(true);
    }
  };
  const storeAppointment = () => {
    const formData = new FormData();
    formData.append("name", `${user.name}`);
    formData.append("phone", `${user.phone}`);
    formData.append("email", `${user.email}`);
    formData.append("date", `${user.date}`);
    formData.append("time", `${user.time}`);
    formData.append("message", `${user.message}`);

    axios({
      method: "post",
      url: `https://admin.cornwallsecuritygroup.com/api/guest/storeAppointmentDetails`,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then((response) => {
        console.log(response);
        console.log("/////////////Contact Form Response/////////////");
        console.log(response.data.success);
        if (response.data.success == "Appointment created successfully") {
          // alert("Form submitted successfully");
          hideModel();
          swal.fire({
            title: "Success",
            text: response.data.success,
            icon: "success",
          });
        }

        setUser({});
      })
      .catch((error) => {
        console.log(error);
        hideModel();
        swal.fire({
          title: "Error",
          text: "Something went wrong please try agian later.",
          icon: "error",
        });
      });
  };
  return (
    <Modal
      centered
      show={isVisible}
      onHide={hideModel}
      className=""
      // size={isDesktopOrLaptop ? "md" : "sm"}
    >
      <Modal.Header closeButton style={{ border: "none" }}>
        <Modal.Title className="poppins-semiBold">
          Schedule an Appoinment
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <Form
          noValidate
          validated={formValidate}
          onSubmit={handleSubmit}
          className="ms-3"
        >
          <Form.Text className="poppins-medium my-5 txt-black ">
            Book your appointment now for seamless service at a time.
          </Form.Text>
          <Container fluid className="py-3 px-0 ">
            <Row className="g-3">
              <Col xs={12} md={6} lg={5} className="my-3">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control
                    className="poppins-regular input"
                    type="text"
                    placeholder="Name"
                    size="md"
                    value={user.name || ""}
                    onChange={(e) => setUser({ ...user, name: e.target.value })}
                    required
                  />
                  {errorMessages.name && (
                    <p className="text-danger">{errorMessages.name}</p>
                  )}
                </Form.Group>
              </Col>
              <Col
                xs={12}
                md={6}
                lg={5}
                className={`my-3 ${isDesktopOrLaptop && "mx-4"} `}
              >
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Control
                    className="poppins-regular input"
                    type="tel"
                    placeholder="Phone number"
                    pattern="[0-9]{10}"
                    size="md"
                    value={user.phone || ""}
                    onChange={(e) =>
                      setUser({ ...user, phone: e.target.value })
                    }
                    required
                  />
                  {errorMessages.phone && (
                    <p className="text-danger">{errorMessages.phone}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="exampleForm.ControlInput3">
              <Form.Control
                className="poppins-regular input "
                type="email"
                placeholder="E-mail"
                size="md"
                value={user.email || ""}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                required
                style={{ width: "400px" }}
              />
              {errorMessages.email && (
                <p className="text-danger">{errorMessages.email}</p>
              )}
            </Form.Group>
            <Row className="g-3 mt-2">
              <Col xs={12} md={6} lg={5} className="my-3">
                <Form.Group controlId="exampleForm.ControlInput4">
                  <Form.Control
                    className="poppins-regular input"
                    type="date"
                    placeholder="date"
                    size="md"
                    value={user.date || ""}
                    onChange={(e) => setUser({ ...user, date: e.target.value })}
                    required
                  />
                  {errorMessages.date && (
                    <p className="text-danger">{errorMessages.date}</p>
                  )}
                </Form.Group>
              </Col>
              <Col
                xs={12}
                md={6}
                lg={5}
                className={`my-3 ${isDesktopOrLaptop && "mx-4"} `}
              >
                <Form.Group controlId="exampleForm.ControlInput5">
                  <Form.Control
                    className="poppins-regular input"
                    type="time"
                    placeholder="time"
                    size="md"
                    value={user.time || ""}
                    onChange={(e) => setUser({ ...user, time: e.target.value })}
                    required
                  />
                  {errorMessages.time && (
                    <p className="text-danger">{errorMessages.time}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="my-2 " controlId="ControlInput6">
              <Form.Control
                style={{ width: isDesktopOrLaptop ? "400px" : "318px" }}
                as="textarea"
                rows={3}
                className="poppins-regular input "
                placeholder="Message"
                value={user.message || ""}
                onChange={(e) => setUser({ ...user, message: e.target.value })}
                required
              />
              {errorMessages.message && (
                <p className="text-danger">{errorMessages.message}</p>
              )}
            </Form.Group>
            <Button
              type="submit"
              variant="dark"
              size="md"
              className="poppins-semiBold  mt-4 mx-3 "
              style={{ width: "85%" }}
            >
              Submit
            </Button>
          </Container>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default withSwal(SchedualAppointment);
